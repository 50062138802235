.reading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.readingHeader {
  font-size: calc(10px + 2vmin);
  justify-content: center;
  width: 90%;
}

.reading__filter {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.readingAddReading {
  align-items: center;
  justify-content: right;
}

.readingLink {
  color: #61dafb;
}
