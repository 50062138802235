.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 33%;
  margin-top: 20px;
  width: 30%;
  flex-wrap: wrap;
}

.avatar {
  margin: 8px;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.botao {
  margin: 24px 0 16px;
}

@media screen and (max-width: 600px) {
  .box {
    margin: 0 auto;
    width: 80%;
  }

  .avatar {
    margin: 8px;
  }

  .form {
    width: 100%;
    margin-top: 8px;
  }

  .botao {
    margin: 16px 0;
    padding: 10px;
  }
}
