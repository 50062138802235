.title {
  font-size: 1rem; /* Ajuste o tamanho da fonte conforme necessário */
  font-weight: bold; /* Ajuste o peso da fonte conforme necessário */
  border-bottom: 1px solid;
  color: #584524;
  width: 90%;
  margin-left: 5%;
  line-height: 0;
  margin-top: 40px;
}
.h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
