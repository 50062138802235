.reading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.readingHeader {
  min-height: 20vh;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  width: 90%;
}

.readingMesAno {
  justify-content: left;
  text-align: left;
  display: flex;
  gap: 10px;
}

.readingAddReading {
  align-items: center;
  justify-content: right;
}

.readingLink {
  color: #61dafb;
}
