.vehicle {
  padding: 10px, 20px;
}

.campos {
  display: flex;
  margin: 20px 20px 20px 20px;

  gap: 30px;
}

.botao {
  align-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
