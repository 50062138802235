.aba {
  display: flex;
  margin-left: 60px;
  width: 90%;
}

.header {
  font-size: calc(10px + 2vmin);
  width: 100%;
}

.personalData {
  display: flex;
  gap: 20px;
}
