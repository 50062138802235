
.MuiDataGrid-cell {
  text-align: left;
}

.MuiDataGrid-columnHeader {
  text-align: left;
}
.colorRow {
  background-color: rgba(255, 255, 255, 0.38);
}