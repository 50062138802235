.report {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.reportHeader {
  font-size: calc(10px + 2vmin);
  justify-content: center;
  width: 90%;
}

.reportMesAno {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.reportAddReport {
  align-items: center;
  justify-content: right;
}

.reportLink {
  color: #61dafb;
}

.reportTableHeader {
  background-color: rgb(250, 243, 233);
}

.reportTableBody {
  background-color: rgb(253, 249, 242);
}
