.photoUploader {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  text-align: center;
  gap: 10px;
}

.imageContainer {
  border: 3px solid black;
  border-radius: 15px;
  background-size: cover;
  width: 150px;
  height: 180px;
  padding: 1px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
