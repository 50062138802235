.configuration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.configurationHeader {
  min-height: 20vh;
  display: block;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
  width: 90%;
}
