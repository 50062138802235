.person {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}

.personHeader {
  min-height: 20vh;
  display: block;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  width: 90%;
}

.personFilter {
  justify-content: space-between;
  gap: 10px;
  text-align: left;
  display: flex;
}
