.addicionalCharge {
  padding: 20px;
}

.campos {
  display: flex;
  margin-top: 20px;
  gap: 25px;
  justify-content: center;
  
}

.botao {
  align-content: right;
  margin-top: 20px;
  display:flex;
  justify-content: flex-end;
  gap: 10px;
}