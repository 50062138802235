.card {
  background-color:rgba(255, 255, 255, 0.38);
  border-radius: 20px;
  width: "100%";
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.062);
  color: #1976d2;
  font-family: "Times New Roman", Times, serif;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.205);
  border-width: 1px;
  margin-top: 20px; 
}
