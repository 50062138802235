.addicionalCharge {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.addicionalChargeHeader {
  min-height: 20vh;
  display: block;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: center;
  width: 90%;
}

.addicionalChargeMesAno {
  justify-content: left;
  text-align: left;
  display: flex;
}

.addAddicionalCharge {
  align-items: center;
  justify-content: right;
}

.addicionalChargeLink {
  color: #61dafb;
}

.addicionalChargeMesAno {
  justify-content: left;
  text-align: left;
  display: flex;
  gap: 25px;
}
